@tailwind base;
@tailwind components;
@tailwind utilities;

/* Set the background outside of Tailwind */
body {
  background-image: url('./images/gridlines.svg');
  background-size: 10vw;
  background-repeat: repeat;
  min-height: 100vh;
}
@media (max-width: 767px) {
  body {
      background-size: 30vw;
  }
}

/* Override Plotly cursor and height  */
.js-plotly-plot .plotly .cursor-crosshair {
  cursor: auto !important;
}
.plotly-chart-height {
  height: 27.5vw;
}
@media (max-width: 767px) {
  .plotly-chart-height {
      height: 70vw;
  }
}
.plotly-small-height {
  height: 20vw;
}
@media (max-width: 767px) {
  .plotly-small-height {
      height: 70vw;
  }
}
.plotly-large-height {
  height: 35vw;
}

/* Change our select arrows to minimal */
select.minimal {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image:
    linear-gradient(45deg, #3dbaff 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #3dbaff 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: #3dbaff;
  outline: 0;
}